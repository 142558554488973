import { Link } from "gatsby";
import * as React from "react";

const NotFoundPage = (): React.ReactElement => {
  return (
    <main>
      <title>Not found</title>
      <h1>Not found</h1>
      <Link to="/index">Go to index</Link>
    </main>
  );
};

export default NotFoundPage;
